






































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import LotService from "@/api/lots";
import TableLot from "@/components/lots/TableLot.vue";
import EditLot from "@/components/lots/EditLot.vue";
import LotCard from "@/components/lots/LotCard.vue";

@Component({
  components: {
    TableLot,
    EditLot,
    LotCard
  },
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    }),
    ...mapGetters("LotModule", {
      lots: "getLots",
      lotsHistorial: "getLotsHistorial"
    })
  }
})
export default class Lotes extends Vue {
  isFilterToggled = false;
  isHovering = false;
  isLoading = false;
  isShowingContent = false;
  isAddingDetails = false;
  isHistorial = false;
  content: any = [];
  activeLot = 0;
  showPast = false;
  storage!: any;
  lots!: any[];
  lotsHistorial!: any[];
  updateKey = 0;
  pagination: any = {
    total: 0,
    current_page: 1,
    per_page: 11
  };
  paginationHistorial: any = {
    total: 0,
    current_page: 1,
    per_page: 6
  };
  addDetailsHistorial(lotId: number): void {
    this.isAddingDetails = true;
    this.activeLot = lotId;
    this.isHistorial = true;
  }
  addDetails(lotId: number): void {
    this.isAddingDetails = true;
    this.activeLot = lotId;
  }
  showContent(lotId: number) {
    this.isShowingContent = true;
    this.activeLot = this.lots.find(x => x.id == lotId);
    if (!this.activeLot) {
      this.activeLot = this.lotsHistorial.find(x => x.id == lotId);
    }
  }

  closeTable(data: any) {
    if (this.lots.find(x => x.id == data.lotId)) {
      this.$store.dispatch("LotModule/setSelectedItemData", data);
    } else if (this.lotsHistorial.find(x => x.id == data.lotId)) {
      const index = this.lotsHistorial.findIndex(x => x.id == data.lotId);
      this.lotsHistorial[index].items_data_selected = data.selected;
    }
    this.isShowingContent = false;
    this.updateKey++;
  }
  loadPast(toggleBtn = false) {
    this.isLoading = true;
    if (toggleBtn) {
      this.showPast = !this.showPast;
    }
    LotService.getHistorial(this.storage.id, this.paginationHistorial).then(
      response => {
        this.paginationHistorial.total = response.data.total;
        this.paginationHistorial.current_page = response.data.current_page;
        this.paginationHistorial.per_page = response.data.per_page;
        this.$store.dispatch("LotModule/setLotsHistorial", response.data.data);
        this.isLoading = false;
      }
    );
  }

  getAllLots() {
    this.isLoading = true;
    LotService.getAll(this.storage.id, this.pagination).then(response => {
      this.pagination.total = response.data.total;
      this.pagination.current_page = response.data.current_page;
      this.pagination.per_page = response.data.per_page;
      this.$store.dispatch("LotModule/setLots", response.data.data);
      this.isLoading = false;
    });
  }
  get routeName() {
    return this.$route.name;
  }

  @Watch("pagination.current_page")
  changePage() {
    this.getAllLots();
  }

  @Watch("paginationHistorial.current_page")
  changePageHistorial() {
    this.loadPast();
  }

  mounted() {
    this.getAllLots();
  }
}
