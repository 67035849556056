




































































import { Component, Prop, Vue } from "vue-property-decorator";
import LotService from "@/api/lots";

@Component({})
export default class TableLot extends Vue {
  checkedItemData: any[] = [];
  proposedToCheck: any[] = [];
  @Prop() readonly lot!: any;
  content: any[] = [];

  setSelectedItemData() {
    const itemsSelected = [...new Set(this.checkedItemData.map(x => x.id))];
    LotService.update(this.lot.id, {
      items_data_selected: itemsSelected
    }).then(() => {
      this.$buefy.notification.open({
        message: "Muestras seleccionadas correctamente",
        type: "is-success"
      });
      this.$emit("close", {
        lotId: this.lot.id,
        selected: itemsSelected
      });
    });
  }
  setRowClass(row: any) {
    if (this.checkedItemData.find(x => x.id == row.id)) return "bg-green-300";
    if (this.proposedToCheck.includes(row.id)) return "bg-blue-300";
  }
  mounted() {
    const limit = Math.round(this.lot.weight / 5 / 1000);
    LotService.get(this.lot.id).then(response => {
      this.content = response.data.items;
      this.proposedToCheck = this.content
        .sort((a, b) => {
          return Math.abs(limit - a) - Math.abs(limit - b);
        })
        .map(x => x.id)
        .splice(0, limit);
      if (this.lot.items_data_selected) {
        this.checkedItemData = this.lot.items_data_selected.map((x: number) => {
          return this.content.find(y => y.id == x);
        });
      }
    });
  }
}
