














































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import LotService from "@/api/lots";

@Component({})
export default class LotCard extends Vue {
  @Prop(Object) lot: any;

  @Emit()
  showContent() {
    return this.lot.id;
  }

  editLot() {
    this.$router.push({
      name: "EditarLote",
      params: { lotId: this.lot.id }
    });
  }

  deleteLot() {
    this.$buefy.dialog.confirm({
      message: `¿Eliminar lote ${this.lot.id}?`,
      confirmText: "Eliminar",
      cancelText: "Cancelar",
      onConfirm: () => {
        LotService.delete(this.lot.id);
        this.$buefy.notification.open({
          message: "Se ha eliminado el lote",
          type: "is-warning"
        });
        this.$store.dispatch("LotModule/deleteLot", this.lot.id);
      }
    });
  }

  @Emit()
  addDetails() {
    return this.lot.id;
  }
}
