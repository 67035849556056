






















import LotService from "@/api/lots";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class EditLot extends Vue {
  data = {
    trade_id: "",
    lot_id: ""
  };
  @Prop(Number) readonly lotId!: number;
  @Prop(Boolean) readonly historial!: boolean;

  @Emit()
  close() {
    return false;
  }

  editLot() {
    LotService.update(this.lotId, this.data).then(response => {
      this.$buefy.notification.open({
        message: "El lote se ha actualizado",
        type: "is-success"
      });
      if (this.historial) {
        this.$store.dispatch("LotModule/setLotTradeHistorial", response.data);
      } else {
        this.$store.dispatch("LotModule/setLotTrade", response.data);
      }

      this.$emit("close");
    });
  }
}
